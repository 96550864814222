import { useCallback, useState } from 'react';
import { signInWithProvider } from '@/lib/firebase/auth/utils';
import type { Provider } from '@/lib/firebase/types';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import GoogleIcon from '@mui/icons-material/Google';
import Alert from '@mui/material/Alert';

export default function AccountProviders() {
  const [error, setError] = useState('');
  const handleSignInWithProvider = useCallback(async (provider: Provider) => {
    setError('');
    try {
      await signInWithProvider(provider);
    } catch (error: any) {
      setError(error.message || 'Failed to sign in with provider. Please try again.');
    }
  }, []);

  return (
    <Stack spacing={2}>
      {error && <Alert severity='error'>{error}</Alert>}
      {[{ provider: 'google.com', name: 'Google', icon: <GoogleIcon /> }].map(
        ({ provider, name, icon }) => (
          <Button
            key={provider}
            fullWidth
            variant='outlined'
            onClick={() => handleSignInWithProvider(provider as Provider)}
            startIcon={icon}
          >
            {`Continue with ${name}`}
          </Button>
        ),
      )}
    </Stack>
  );
}
