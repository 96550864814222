'use client';

import { useState, useTransition, useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import type { UserData } from '@/types/schemas';
import { signInWithEmail, signUpWithEmail, createOTP, verifyOTP } from '@/lib/firebase/auth/utils';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import updateUser from '@/lib/firebase/auth/updateUser';
import userExist from '@/lib/firebase/auth/userExist';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

type FormInputs = {
  email: string;
  password: string;
  displayName?: string;
  confirmPassword?: string;
  otp?: string;
  newPassword?: string;
  confirmNewPassword?: string;
};

export default function EmailPassword() {
  const [error, setError] = useState('');
  const [isPending, startTransition] = useTransition();
  const [isRecovery, setIsRecovery] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [emailExists, setEmailExists] = useState<boolean | null>(null);

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
    reset,
    watch,
  } = useForm<FormInputs>();

  const email = watch('email');

  useEffect(() => {
    setEmailExists(null);
  }, [email]);

  const handleEmailCheck = useCallback(async (email: string) => {
    try {
      const exists = await userExist(email);
      setEmailExists(exists);
      return exists;
    } catch (error: any) {
      setError(error.message || 'Failed to check email. Please try again.');
      return null;
    }
  }, []);

  const onSubmit = useCallback(
    async (data: FormInputs) => {
      setError('');

      if (emailExists === null) {
        const exists = await handleEmailCheck(data.email);
        if (exists === null) return; // Error occurred during check
        return;
      }

      startTransition(async () => {
        try {
          const {
            email,
            password,
            displayName,
            confirmPassword,
            otp,
            newPassword,
            confirmNewPassword,
          } = data;

          if (isRecovery) {
            if (!otpSent) {
              await createOTP(email);
              setOtpSent(true);
              return;
            }

            if (!otpVerified) {
              const isValid = await verifyOTP(email, otp!);
              if (isValid) {
                setOtpVerified(true);
                return;
              }
              throw new Error('Invalid OTP code');
            }

            if (newPassword !== confirmNewPassword) {
              throw new Error('New passwords do not match');
            }

            await updateUser({ email }, { user: { password: newPassword } });
            setIsRecovery(false);
            reset();
            return;
          }

          if (!emailExists) {
            if (password !== confirmPassword) {
              throw new Error('Passwords do not match');
            }
            await signUpWithEmail<UserData>(
              {
                user: { email, password, displayName },
                userData: { email, displayName },
              },
              true,
            );
          } else {
            await signInWithEmail(email, password);
          }
        } catch (error: any) {
          setError(error.message || 'Authentication failed. Please try again.');
        }
      });
    },
    [emailExists, isRecovery, otpSent, otpVerified, reset, handleEmailCheck],
  );

  const handleRecoveryClick = useCallback(() => {
    setIsRecovery(true);
    setOtpSent(false);
    setOtpVerified(false);
    reset();
  }, [reset]);

  const handleResetClick = useCallback(() => {
    setEmailExists(null);
    setError('');
    reset();
  }, [reset]);

  const buttonText = useMemo(() => {
    if (isRecovery) {
      if (otpVerified) return 'Reset Password';
      if (otpSent) return 'Verify OTP';
      return 'Send OTP';
    }

    if (emailExists === null) return 'Continue';

    return emailExists ? 'Sign In' : 'Sign Up';
  }, [isRecovery, otpSent, otpVerified, emailExists]);

  return (
    <Stack component='form' onSubmit={handleSubmit(onSubmit)} spacing={2.5} width='100%'>
      {error && <Alert severity='error'>{error}</Alert>}
      <TextField
        {...register('email', {
          required: true,
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: 'Invalid email address',
          },
        })}
        fullWidth
        id='email'
        label='Email Address'
        placeholder='Enter your email address'
        autoComplete='email'
        size='small'
        autoFocus
        disabled={isSubmitting || isPending || (isRecovery && otpSent)}
        error={!!errors.email}
        helperText={errors.email?.message}
      />
      {emailExists !== null && !isRecovery && (
        <>
          {emailExists ? (
            <TextField
              {...register('password', { required: true })}
              fullWidth
              label='Password'
              placeholder='Enter your password'
              type='password'
              id='password'
              autoComplete='current-password'
              disabled={isSubmitting || isPending}
              size='small'
            />
          ) : (
            <>
              <TextField
                {...register('displayName', { required: true })}
                fullWidth
                label='Full Name'
                placeholder='Enter your full name'
                id='displayName'
                autoComplete='name'
                size='small'
                disabled={isSubmitting || isPending}
              />

              <TextField
                {...register('password', { required: true })}
                fullWidth
                label='Password'
                placeholder='Create a password'
                type='password'
                id='password'
                autoComplete='new-password'
                disabled={isSubmitting || isPending}
                size='small'
              />

              <TextField
                {...register('confirmPassword', { required: true })}
                fullWidth
                label='Confirm Password'
                placeholder='Confirm your password'
                type='password'
                id='confirmPassword'
                autoComplete='new-password'
                disabled={isSubmitting || isPending}
                size='small'
              />
            </>
          )}
        </>
      )}
      {isRecovery && (
        <>
          {otpSent && !otpVerified && (
            <TextField
              {...register('otp', { required: true })}
              fullWidth
              label='OTP Code'
              placeholder='Enter the OTP sent to your email'
              id='otp'
              size='small'
              disabled={isSubmitting || isPending}
            />
          )}

          {otpVerified && (
            <>
              <TextField
                {...register('newPassword', { required: true })}
                fullWidth
                label='New Password'
                placeholder='Enter new password'
                type='password'
                id='newPassword'
                autoComplete='new-password'
                size='small'
                disabled={isSubmitting || isPending}
              />

              <TextField
                {...register('confirmNewPassword', { required: true })}
                fullWidth
                label='Confirm New Password'
                placeholder='Confirm new password'
                type='password'
                id='confirmNewPassword'
                autoComplete='new-password'
                size='small'
                disabled={isSubmitting || isPending}
              />
            </>
          )}
        </>
      )}
      <Button
        type='submit'
        fullWidth
        variant='contained'
        disabled={isSubmitting || isPending}
        loading={isSubmitting || isPending}
      >
        {buttonText}
      </Button>
      {emailExists !== null && !isRecovery && (
        <Stack direction='row' spacing={1} justifyContent='center'>
          {emailExists ? (
            <Button
              variant='text'
              size='small'
              onClick={handleRecoveryClick}
              disabled={isSubmitting || isPending}
              fullWidth
            >
              Forgot Password?
            </Button>
          ) : (
            <Button
              variant='text'
              size='small'
              onClick={handleResetClick}
              disabled={isSubmitting || isPending}
              startIcon={<ArrowBackIcon />}
              fullWidth
            >
              Back
            </Button>
          )}
        </Stack>
      )}
    </Stack>
  );
}
