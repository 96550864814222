'use client';

import { ReactNode, useState, useCallback, useTransition } from 'react';
import { Color } from '@/types/schemas';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export interface DialogButtonProps {
  label: string;
  color?: Color;
  variant?: 'text' | 'outlined' | 'contained';
  onClick?: () => Promise<void>;
  loading?: boolean;
  disabled?: boolean;
  closeDialog?: boolean;
}

interface CustomDialogProps {
  title: string;
  children: ReactNode;
  actions?: DialogButtonProps[];
  onClose?: () => void;
}

export default function CustomDialog({
  trigger,
  title,
  children,
  actions = [],
  onClose,
}: CustomDialogProps & {
  trigger: ReactNode;
}) {
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  return (
    <>
      <span onClick={handleOpen}>{trigger}</span>

      <DialogBase title={title} actions={actions} onClose={onClose} open={open} setOpen={setOpen}>
        {children}
      </DialogBase>
    </>
  );
}

export function DialogBase({
  title,
  children,
  actions = [],
  onClose,
  open,
  setOpen,
}: CustomDialogProps & {
  open: boolean;
  setOpen: (open: boolean) => void;
}) {
  const handleClose = useCallback(() => {
    setOpen(false);
    onClose?.();
  }, [onClose, setOpen]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth='xs' fullWidth>
      <DialogTitle
        variant='h5'
        sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', py: 1.5 }}
      >
        {title}
        <IconButton aria-label='close' onClick={handleClose} edge='end' size='small'>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        dividers
        sx={{
          borderBottom: actions.length > 0 ? '1px solid' : 'none',
          borderColor: 'divider',
        }}
      >
        {children}
      </DialogContent>
      {actions.length > 0 && (
        <DialogActions>
          {actions.map((action, index) => (
            <ActionButton key={index} action={action} handleClose={handleClose} />
          ))}
        </DialogActions>
      )}
    </Dialog>
  );
}

function ActionButton({
  action,
  handleClose,
}: {
  action: DialogButtonProps;
  handleClose: () => void;
}) {
  const [isLoading, startTransition] = useTransition();

  return (
    <Button
      onClick={() => {
        startTransition(async () => {
          await action.onClick?.();
          if (action.closeDialog) {
            handleClose();
          }
        });
      }}
      variant={action.variant}
      disabled={action.disabled}
      loading={isLoading}
      color={action.color}
    >
      {action.label}
    </Button>
  );
}
