import { Role } from '@/types/schemas';
import { ADMIN_ROLE, EMPLOYEE_ROLE, CUSTOMER_ROLE } from './roles';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import EventIcon from '@mui/icons-material/Event';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import GroupIcon from '@mui/icons-material/Group';
import BadgeIcon from '@mui/icons-material/Badge';
import NotificationsIcon from '@mui/icons-material/Notifications';
import LocationOnIcon from '@mui/icons-material/LocationOn';
export const ACCOUNT_INFO = {
  label: 'Account Information',
  description:
    'Update your personal details, manage security settings, and customize your account preferences.',
  icon: AccountCircleIcon,
  path: '/account',
  rolesAllowed: [ADMIN_ROLE, EMPLOYEE_ROLE, CUSTOMER_ROLE],
};

export const ORDERS = {
  label: 'Orders',
  description:
    'Track order status, view order history, manage returns, and access purchase receipts.',
  icon: ShoppingBagIcon,
  path: '/orders',
  rolesAllowed: [ADMIN_ROLE, EMPLOYEE_ROLE, CUSTOMER_ROLE],
  collection: 'orders',
};

export const CALENDAR = {
  label: 'Appointments',
  description:
    'Schedule new appointments, view upcoming sessions, reschedule, or cancel existing bookings.',
  icon: EventIcon,
  path: '/calendar',
  rolesAllowed: [ADMIN_ROLE, EMPLOYEE_ROLE, CUSTOMER_ROLE],
};

export const CONTACT = {
  label: 'Customer Support',
  description:
    'Connect with our support team via live chat, submit help tickets, or browse our FAQ knowledge base.',
  icon: SupportAgentIcon,
  path: '/contact',
  rolesAllowed: [ADMIN_ROLE, EMPLOYEE_ROLE, CUSTOMER_ROLE],
};

export const CUSTOMERS = {
  label: 'Customers',
  description:
    'View customer profiles, track purchasing behavior, manage account status, and access customer analytics.',
  icon: GroupIcon,
  path: '/customers',
  rolesAllowed: [ADMIN_ROLE],
};

export const EMPLOYEES = {
  label: 'Employees',
  description:
    'Manage staff accounts, track performance metrics, update roles and permissions, and manage schedules.',
  icon: BadgeIcon,
  path: '/employees',
  rolesAllowed: [ADMIN_ROLE],
};

export const NOTIFICATIONS = {
  label: 'Notifications',
  description:
    'View system alerts, appointment reminders, order updates, and customize notification preferences.',
  icon: NotificationsIcon,
  path: '/notifications',
  rolesAllowed: [ADMIN_ROLE, EMPLOYEE_ROLE, CUSTOMER_ROLE],
};

export const ADDRESSES = {
  label: 'Addresses',
  description: 'View your addresses and add a new address',
  icon: LocationOnIcon,
  path: '/addresses',
  rolesAllowed: [CUSTOMER_ROLE],
};

export const MENU_ITEMS = [
  ACCOUNT_INFO,
  NOTIFICATIONS,
  ADDRESSES,
  ORDERS,
  CALENDAR,
  CONTACT,
  CUSTOMERS,
  EMPLOYEES,
];

export function getMenuItems(role: Role) {
  return MENU_ITEMS.filter((item) => item.rolesAllowed.includes(role));
}
