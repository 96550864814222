'use client';

import useAuth from '@/hooks/useAuth';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import AccountProviders from './AccountProviders';
import EmailPassword from './EmailPassword';
import AuthIcon from '../AuthIcon';

export default function AuthenticationForm() {
  const { loaded } = useAuth();

  if (!loaded) {
    return (
      <Stack
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '100%',
          py: 4,
        }}
      >
        <CircularProgress color='secondary' />
        <Typography variant='body2' color='text.secondary' sx={{ mt: 2 }}>
          Loading authentication options...
        </Typography>
      </Stack>
    );
  }

  return (
    <Stack spacing={3} sx={{ width: '100%', pb: 2 }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          mb: 1,
        }}
      >
        <AuthIcon />
        <Typography component='h2' variant='h6' align='center'>
          Welcome Back
        </Typography>
        <Typography variant='body2' color='text.secondary' align='center'>
          Sign in to access your account
        </Typography>
      </Box>

      <Paper elevation={0} variant='outlined' sx={{ px: 2, py: 2.5, borderRadius: 2 }}>
        <EmailPassword />
      </Paper>

      <Divider sx={{ my: 1 }}>
        <Typography variant='body2' color='text.secondary'>
          OR CONTINUE WITH
        </Typography>
      </Divider>

      <AccountProviders />

      <Typography variant='caption' color='text.secondary' align='center' sx={{ mt: 1 }}>
        By signing in, you agree to our Terms of Service and Privacy Policy
      </Typography>
    </Stack>
  );
}
